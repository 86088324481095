import React, { useEffect } from 'react';
import Aux from "../hoc/_Aux/Index";
import Header from "../Components/Header";
import ServicesIntro from './ServicesIntro';
import ServiceList from './ServiceList';
import ServiceBenefits from './ServiceBenefits';
import Footer from '../Components/Footer';
const Services = (props) => {
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
    return(
        <Aux>
        <div className="fixed-scroll-nav">
        
          <Header />
  
          <section className="breadcrumb_section text-center section_padding">
            <h1 className="ss-white">Services</h1>
          </section>

<ServicesIntro />
<ServiceList />
<ServiceBenefits />
<Footer />
          </div>
          </Aux>
    )
}

export default Services;