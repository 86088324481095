import React from "react";
import gallery1 from '../../Assets/images/gallery/gallery-1.jpg'
import gallery2 from '../../Assets/images/gallery/gallery-2.jpg'
import gallery3 from '../../Assets/images/gallery/gallery-3.jpg'
import gallery4 from '../../Assets/images/gallery/gallery-4.jpg'
import gallery5 from '../../Assets/images/gallery/gallery-5.jpg'
import gallery6 from '../../Assets/images/gallery/gallery-6.jpg'

const Gallery = (props) => {
  return (
    <section className="gallery_area">
      <div className="gallery_width">
        <div className="gallery_fixed_height">
          <img src={gallery1} alt="gallery 1" />
          <div className="gallery_overlay">
            <h3>Some of our projects</h3>
            {/* <a href="#" className="gallery_icon_btn text-right">
              <i className="fa fa-angle-right"></i>
            </a> */}
          </div>
        </div>
      </div>
      <div className="gallery_width">
        <div className="gallery_fixed_height">
          <img src={gallery2} alt="gallery 2" />
          <div className="gallery_overlay">
            <h3>Some of our projects</h3>
            {/* <a href="#" className="gallery_icon_btn text-right">
              <i className="fa fa-angle-right"></i>
            </a> */}
          </div>
        </div>
      </div>
      <div className="gallery_width">
        <div className="gallery_fixed_height">
          <img src={gallery3} alt="gallery 3" />
          <div className="gallery_overlay">
            <h3>Some of our projects</h3>
            {/* <a href="#" className="gallery_icon_btn text-right">
              <i className="fa fa-angle-right"></i>
            </a> */}
          </div>
        </div>
      </div>
      <div className="gallery_width">
        <div className="gallery_fixed_height">
          <img src={gallery4} alt="gallery 4" />
          <div className="gallery_overlay">
            <h3>Some of our projects</h3>
            {/* <a href="#" className="gallery_icon_btn text-right">
              <i className="fa fa-angle-right"></i>
            </a> */}
          </div>
        </div>
      </div>
      <div className="gallery_width">
        <div className="gallery_fixed_height">
          <img src={gallery5} alt="gallery 5" />
          <div className="gallery_overlay">
            <h3>Some of our projects</h3>
            {/* <a href="#" className="gallery_icon_btn text-right">
              <i className="fa fa-angle-right"></i>
            </a> */}
          </div>
        </div>
      </div>
      <div className="gallery_width">
        <div className="gallery_fixed_height">
          <img src={gallery6} alt="gallery 6" />
          <div className="gallery_overlay">
            <h3>Some of our projects</h3>
            {/* <a href="#" className="gallery_icon_btn text-right">
              <i className="fa fa-angle-right"></i>
            </a> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
