import React from 'react';
import OwlCarousel from "react-owl-carousel";

const Testimonials = (props) => {
    return(
        <section className="testimonial_area text-center section_padding">
    <div className="container">
        <div className="row">
            <div className="col-md-8 col-md-offset-2">
            <OwlCarousel
          className="testimonial_slider owl-carousel"
          dots
          autoplay
          loop
          animateIn="slideInLeft"
          animateOut="fadeOutRight"
          nav= {true}

            navText= {["<i class='fa fa-angle-left'>", "<i class='fa fa-angle-right'>"]}
          autoplayTimeout={7000}
          items={1}
          autoplaySpeed={300}
        >
               
                    <div className="testimonial_details">
                        <p>As a local realtor, I receive many home inspection reports regarding HVAC. Superior Solutions always identifies any deficiencies and discusses with my clients before proceeding. I highly recommend them for all your service needs.</p>
                        <h4>Sabrina L.,</h4>
                        <span>Tomball</span>
                    </div>
                    <div className="testimonial_details">
                        <p>Superior Solutions is the BEST. They have been performing quarterly preventative maintenance on all of our commercial locations for the past nine years. This has cut our operating costs significantly. They were able to prevent equipment breakdown by properly maintaining the systems.</p>
                        <h4>Ali V.</h4>
                        <span>Metro PCS</span>
                    </div>
                    <div className="testimonial_details">
                        <p>I called my home warranty company because my AC wasn’t cooling as well as it normally did. They sent out three different companies and they all gave me the run around. Superior Solutions AC & Heating fixed it on the first trip out. It was just a bad contactor in the condensing unit.</p>
                        <h4>Bob R.</h4>
                        <span>Cypress</span>
                    </div>
                    <div className="testimonial_details">
                        <p>Our AC quit blowing cold yesterday. I called SUPERIOR SOLUTIONS and the technician was able to show up at my house two hours later.   The capacitor was broke, he had the part in his truck... it only took them 30 minutes to fix it and It was cooling down immediately. Overall great service..</p>
                        <h4>Karen R.</h4>
                        <span>Houston</span>
                    </div>
               </OwlCarousel>
            </div>
        </div>
    </div>

</section>
    )
}

export default Testimonials;