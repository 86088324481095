import React from 'react'
import service1 from '../../Assets/images/services/service-1.jpg'
import service2 from '../../Assets/images/services/service-2.jpg'
import service3 from '../../Assets/images/services/service-3.jpg'
import { Link } from "react-router-dom";
const ServiceList = (props) =>{
    return(
        <section className="service_area section_padding">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="hero-section-title text-center">
                    <h1>Our services</h1>
                </div>
            </div>
            <div className="col-md-4 col-sm-6">
                <div className="service_box">
                    <div className="service_img">
                        <img src={service1} alt="service" />
                        <div className="icon-box">
                            <i className="icon-snow"></i>
                        </div>
                    </div>
                    <div className="service_details">
                       
                       <h2>Residential</h2>
                        <p>
                        We service all residential HVAC equipment, including single family homes, multi-family housing and mobile homes.
                        </p>
                        <Link to="/residential-details" className="btn-yellow">LEARN MORE</Link>
                    </div>
                </div>
            </div>
            
            <div className="col-md-4 col-sm-6">
                <div className="service_box">
                    <div className="service_img">
                        <img src={service3} alt="service" />
                        <div className="icon-box">
                            <i className="icon-ac"></i>
                        </div>
                    </div>
                    <div className="service_details">
                   <h2>Beach/Coastal</h2>
                        <p>
                        We offer service to many Texas coastal communities. Are you looking for a reliable trustworthy company to resolve the issues at your vacation home. Tired of trying to track down a local that can help? Coastal properties require specific treatment due to the corrosive environment and location of equipment.
                        </p>
                        <Link to="/service-details" className="btn-yellow">LEARN MORE</Link>
                        
                    </div>
                </div>
            </div>
            <div className="col-md-4 col-sm-6">
                <div className="service_box">
                    <div className="service_img">
                        <img src={service2} alt="service" />
                        <div className="icon-box">
                            <i className="icon-fire"></i>
                        </div>
                    </div>
                    <div className="service_details">
                       
                      <h2>Commercial</h2>
                        <p>
                        We service all commercial HVAC equipment, including stand alone buildings, strip centers, mid rise and high rise buildings.
                        </p>
                        <Link to="/commercial-details" className="btn-yellow">LEARN MORE</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    )
}

export default ServiceList;