import React, { useState } from "react";
import logoImg from '../../Assets/images/logo.png'
import { Link } from "react-router-dom";

const Header = (props) => {
    const [menuState, setMenuState] = useState(false);

   
  return (
    <div className="main_menu_area menu_style_2">
    <div className="container-fluid">
        <div className="row ss-flex">
            <div className="col-md-4 col-xs-12 responsive_full_width">
                <button type="button" onClick={()=>{
                    setMenuState(!menuState)
                }} className= {menuState?"navbar-toggles navbar_close iconbar":"navbar-toggles iconbar"}>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>
                
                    <img src={logoImg} alt="Logo" />
                
            </div>
            <div className={menuState?"col-md-8 col-sm-6 collapse_responsive collapse_active":"col-md-8 col-sm-6 collapse_responsive"} >
                <div className="collapse navbar-collapse remove_padding" id="myNavbar">
                    <ul className="nav navbar-nav text-center main_nav_2">
                        <li>
                            <a href="/" >Home</a>
                        </li>
                        <li><Link to="/aboutus">About</Link></li>
                        <li><Link to="/services">Services</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                        
                        <li className="header_right_btn m5">
                            <Link className="btn-yellow" to="/schedule-now">Schedule Now</Link>
                        </li>
                    </ul>
                </div>
            </div>
            
        </div>
    </div>
</div>
  );
};

export default Header;