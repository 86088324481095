import React from 'react';
import backdropimg from '../../Assets/images/shape/about-shape.png'
const Trust = (props) =>{
    return(
        <section className="about_style_3_area section_padding">
    <div className="container">
        <div className="row">
            <div className="col-md-4 col-sm-6">
                <div className="about_image_box"></div>
            </div>
            <div className="col-md-3 col-sm-6">
                <div className="about_image_box about_image_box2"></div>
            </div>
            <div className="col-md-5 col-sm-6">
                <div className="about_image_box about_image_box3"></div>
            </div>

           
            <div className="col-md-4 col-sm-6">
                <div className="about_style_3_details">
                   
                    <p>Superior Solutions is an air conditioning and heating contracting company, based in Northwest Houston. Established in early 2000, we began serving the residential and commercial markets. After years of providing valued customer service and common sense answers to our customers, we have grown into a complete service company that can handle any size HVAC project.</p>
                </div>
            </div>

            <div className="col-md-4 col-sm-6">
                <div className="about_style_3_details">
                <img src={backdropimg} className="about_us_shape" alt="about shape" />
                    <p>Superior Solutions is a state of Texas class A license holder which allows us to work on equipment of any size. All of our employees are background checked, drug tested, and registered with the State of Texas department of license and regulations (TLDR).</p>
                </div>
            </div>
        </div>
    </div>
</section>
    )
}

export default Trust;