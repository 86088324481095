import React from "react";
import FooterImage from '../../Assets/images/shape/footer-shape.png'

const Footer = (props) => {
  return (
    <footer className="footer_area">
      <div className="footer_contact text-center">
        <div className="container">
          <div className="col-md-4 col-sm-4">
            <div className="footer_contact_width text-left">
              <p>
                <i className="icon-placeholder"></i> Address{" "}
                <span>12406 Raven South Dr Cypress, <br /><span className="m-l-35">TX 77429</span> </span>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <div className="footer_contact_width">
              <p>
                <i className="icon-contact"></i> Email{" "}
                <span>
                  <a href="mailto:layerdrops@gmail.com">superiorfax@yahoo.com</a>
                </span>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <div className="footer_contact_width text-right">
              <p>
                <i className="icon-clock"></i> Open Hours{" "}
                <span>
                 Mon-Sat: 8am - 8pm
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_content section_padding" style={{paddingTop:'0px'}}>
      
          <div className="container">
          <div className="copyright_area" style={{marginTop:'0px', border:'0px'}}>
            <div className="row">
              <div className="col-md-8 col-sm-6 copyright_text">
                <p>&copy; Copyright 2020 by Superior Solutions</p>
              </div>
              <div className="col-md-4 col-sm-6 copyright_social text-right">
                <ul>
                  <li>
                    <a href="https://twitter.com">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://facebook.com">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          </div>
      </div>
      <img
        src={FooterImage}
        className="footer_shape"
        alt="footer shape"
      />
    </footer>
  );
};

export default Footer;
