import React from 'react'
import imglogo from '../../Assets/images/shape/service-color-shape.png'
const ServicesIntro = (props) => {
    return(
        <div className="service_style_2_area text-center section_padding">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="service_img_info_box">
                    <img src={imglogo} className="service_info_color_shape" alt="shape" />
                </div>
                <p className="col-md-10 col-md-offset-1">Superior Solutions is an air conditioning and heating contracting company, based in Northwest Houston. Established in early 2000, we began serving the residential and commercial markets. After years of providing valued customer service and common sense answers to our customers, we have grown into a complete service company that can handle any size HVAC project.</p>
            </div>
        </div>
    </div>
</div>
    )
}

export default ServicesIntro;