import React, { useEffect } from 'react';
import Aux from "../hoc/_Aux/Index";
import Header from "../Components/Header";
import Footer from '../Components/Footer';
import Trust from './Trust';
import WhyUs from './WhyUs';
import NeedHelp from '../Components/NeedHelp';
const AboutUs = (props) => {
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
    return(
        <Aux>
      <div className="fixed-scroll-nav">
      
        <Header />

        <section className="breadcrumb_section text-center section_padding">
          <h1 className="ss-white">About</h1>
        </section>
        <Trust />
        <NeedHelp />
        <WhyUs />
        <Footer />
        </div>
        </Aux>
    )
}

export default AboutUs;