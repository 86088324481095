import React, { useState } from 'react';
import Select from 'react-select';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import validator from 'validator';
import InputMask from 'react-input-mask'
import * as config from '../Config/Config';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const preferredContactMethod = [{
    label:'Preferred contact method : Phone',
    value:'phone'
},{
    label:'Preferred contact method : Email',
    value:'email'
}]

const initialValue = {
    name:'',
    email:'',
    phone:'',
    preferred:preferredContactMethod[0],
    message:''
}

const ContactForm = (props) => {
    const MySwal = withReactContent(Swal);
    const [contactForm, setContactForm] = useState(initialValue);
    const [validMobile, setValidMobile] = useState(false);
    

    const inputChangeHandler = (e) => {
        var formValues = {
            ...contactForm
        }
        formValues[e.target.name] = e.target.value;
        if(e.target.name==="phone"){
            if(validator.isMobilePhone(e.target.value)){
              setValidMobile(false);
            }else{
              setValidMobile(true);
            }
          }
        setContactForm(formValues);
    }


    const styles = {
        control: style=>({
            ...style,
            borderRadius: '2px',
            background: '#F1F1F1',
            borderColor: '#F1F1F1',
            paddingLeft: '12px',
            color: '#87868a',
            marginBottom:'20px',
            fontFamily:'"Josefin Sans",Helvetica Neue",Helvetica,Arial,sans-serif',
            fontWeight:'400'
  
        }),
        singleValue: style=>({
            ...style,
            color:'#87868a'
        })
    }

    const onSubmit = (e) =>{
        e.preventDefault();
        if(contactForm.phone!=="" && contactForm.phone!==null){
        
        fetch(`${config.apiUrl}/schedule/postContactForm`,{
            method:"POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
              },
            body: JSON.stringify(contactForm)
        }).then((response)=>{
            if(response.ok){
                setContactForm(initialValue);
                MySwal.fire({
                    title: 'Success',
                    text:'Your enquiry is submitted. Thank you!'
                  })
            }else{
                if (response.status === 401) {
                    MySwal.fire({
                      title: "Warning",
                      text: "You are not authorized to view this content",
                    });
                  }else if(response.status===501){
                    MySwal.fire({
                      title: "Warning",
                      text: "Status update failed. Please try again.",
                    });
                  } else {
                    MySwal.fire({
                      title: "Server Error",
                      text: "Please try later.",
                    });
                  }
            }
        })
    }else{
        setValidMobile(true)
    }
    }

    return(
        <section className="contact_form_area contact_us section_padding">
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="contact-right contact_details">
                    <div className="hero-title-with-shape">
                        <h4 className="heading_with_border">Contact with us</h4>
                        <h1>We offer 24/7 emergency service to all of our customers</h1>
                    </div>
                    
                    <div className="contact_location_box">
                        <div className="contact_location_map">
                            <span className="icon-location"></span>
                            <p>12406 <br/>Raven South <br />Cypress, TX 77429</p>
                        </div>
                        <div className="contact_location_map contact_location_call">
                            <span className="icon-contact_call"></span>
                            <p>superiorfax@yahoo.com<br />281 806 9876</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="contact-right contact-right-style-2 responsive_mt">
                    <ValidationForm onSubmit={onSubmit} onErrorSubmit={()=>{

    if(validator.isMobilePhone(contactForm.phone)){
        setValidMobile(false);
      }
      else
      {
        setValidMobile(true)
      }
                    }}>
                        <TextInput className="input" type="text" name="name" value={contactForm.name} required onChange={inputChangeHandler} placeholder="Your name" />
                        <TextInput className="input" type="text" name="email" value={contactForm.email} validator={validator.isEmail} errorMessage = {{
                        validator: 'Please enter valid email'
                      }} onChange={inputChangeHandler} placeholder="Email address" />
                        <InputMask mask="999-999-9999" name="phone" autoComplete="off" id="phone" required 
                    value={contactForm.phone} placeholder="Mobile" onChange={inputChangeHandler} />
                          {validMobile? <div class="invalid-feedback">Please enter valid mobile</div>:null}
                        <Select styles={styles} name="preferred" placeholder="Preferred contact method" defaultValue={preferredContactMethod[0]} value={contactForm.preferred} options={preferredContactMethod} onChange={(e)=>{
                            var formValues = {
                                ...contactForm
                            }
                            formValues["preferred"] = e
                            
                            setContactForm(formValues);
                        }} isClearable />
                        <textarea name="message" id="message" value={contactForm.message} onChange={inputChangeHandler} cols="20" rows="5" placeholder="Write message"></textarea>
                        <button className="btn-yellow" value="SUBMIT NOW">SUBMIT NOW</button>
                    </ValidationForm>
                </div>
            </div>
        </div>
    </div>
</section>
    )
}

export default ContactForm;