import React, { useEffect } from 'react';
import Aux from "../hoc/_Aux/Index";
import Header from "../Components/Header";
import ContactForm from './ContactForm';
import Footer from '../Components/Footer';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Contact = (props) => {

  const defaultProps = {
    center: {
        lat: 29.951660,
        lng: -95.622755
    },
    zoom: 11
};


  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
    return(
        <Aux>
        <div className="fixed-scroll-nav">
        
          <Header />
  
          <section className="breadcrumb_section text-center section_padding">
            <h1 className="ss-white">Contact</h1>
          </section>
          
          <ContactForm />
          <div className="map_section">
           
            <div style={{ height: '650px', width: '100%' }}>
                        <GoogleMapReact
                        bootstrapURLKeys={{key:"AIzaSyCqjirSEIrjA4OQSSYEF2lwboa4CVLkvmw"}}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                        >
                        <AnyReactComponent
                            lat={29.951660}
                            lng={-95.622755}
                            text="Superior Solutions"
                        />
                        </GoogleMapReact>
                    </div>
          </div>
          <Footer />
          </div>
          </Aux>
    )
}

export default Contact;