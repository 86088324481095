import React, { useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Aux from '../hoc/_Aux/Index';
import ScheduleForm from './ScheduleForm';

const ScheduleNow = (props) => {
    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])
    return(
        <Aux>
            <div className="fixed-scroll-nav">
        <Header />
        <section className="breadcrumb_section text-center section_padding">
         
          <h1 className="ss-white">Schedule Now</h1>
        </section>
        <ScheduleForm />
        <Footer />
        </div>
        </Aux>
    )
}

export default ScheduleNow;
