import React from "react";
import { Link } from "react-router-dom";

const StartStory = (props) => {
  return (
    <section className="about_area section_padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="about_image about-image__updated"></div>
          </div>
          <div className="col-md-6">
            <div className="about_details">
              
              <p>
              Superior Solutions is an air conditioning and heating contracting company, based in Northwest Houston. Established in early 2000, we began serving the residential and commercial markets. After years of providing valued customer service and common sense answers to our customers, we have grown into a complete service company that can handle any size HVAC project.
              </p>
              <Link className="btn-yellow" to="/aboutus">LEARN MORE</Link>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StartStory;