import React from 'react';

const ServiceBenefits = (props) => {
    return(
<section class="service_benefits_area text-center section_border section_padding">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="hero-section-title">
                    <h1>Our benefits</h1>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="service_benefits_box">
                    <i class="icon-big_clock"></i>
                    <h3>Emergency Service</h3>
                   
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="service_benefits_box">
                    <i class="icon-wallet"></i>
                    <h3>No Upfront Payment</h3>
                   
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="service_benefits_box">
                    <i class="icon-diploma"></i>
                    <h3>Satisfaction Guarantee</h3>
                    
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="service_benefits_box">
                    <i class="icon-mechanic"></i>
                    <h3>20+ Years Experience</h3>
                    
                </div>
            </div>
        </div>
    </div>
</section>
    )
}

export default ServiceBenefits;