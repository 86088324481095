import React, { useEffect } from 'react';
import Aux from "../hoc/_Aux/Index";
import Header from "../Components/Header";
import Details from './Details';
import Footer from '../Components/Footer';


const ServiceDetails = (props) => {
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
    return(
        <Aux>
        <div className="fixed-scroll-nav">
        
          <Header />
  
          <section className="breadcrumb_section text-center section_padding">
            <h1 className="ss-white">Beach/Coastal - Details</h1>
          </section>
          <Details />
          <Footer />
          </div>
          </Aux>
    )
}

export default ServiceDetails;