import React, { useState, useEffect } from "react";
import * as config from '../Config/Config';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const initialValue = {
    username:'',
    password:''
}

const Login = (props) => {

  const [loginForm, setLoginForm] = useState(initialValue);
  
  const MySwal = withReactContent(Swal);

  useEffect(()=>{
    if(sessionStorage.getItem("jwtToken") && sessionStorage.getItem("jwtToken")!==""){
        props.checkAuth(false);
      }else{
        props.checkAuth(true);
      }
      // eslint-disable-next-line
  },[]);
  
  const loginBtnHandler = (event) => {
    event.preventDefault();
    fetch(`${config.apiUrl}/authenticate`,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        body: JSON.stringify(loginForm)
    }).then((response)=>{
        if(response.ok){
            const authResponse = response.json();
            authResponse.then((res)=>{
                sessionStorage.setItem("jwtToken",res.jwtToken);
                props.checkAuth(false);
            })
        }else{
            if(response.status===401){
                MySwal.fire({
                    title:"Warning",
                    text:"You are not authorized to view this content"
                })
            }else{
                MySwal.fire({
                    title:"Server Error",
                    text:"Please try later."
                })
            }
        }
    }).catch((ex)=>{
        MySwal.fire({
            title:"Network Issue",
            text:"Please try later."
        })
    })

   
  }

  const inputChangeHandler = (event) => {
      var formValues = {
          ...loginForm
      }
      formValues[event.target.name] = event.target.value;
      setLoginForm(formValues)
  }

  return (
    <section className="contact_form_area contact_us section_padding">
      <div className="container text-center">
        <div className="row">
          <div className="col">
            <div className="hero-title-with-shape">
              <h4>Login</h4>
            </div>
            <div className="contact-right contact-right-style-2 responsive_mt">
              <form onSubmit={loginBtnHandler}>
                <input type="text" name="username" id="username" onChange={inputChangeHandler} value={loginForm.username} autoComplete="off" placeholder="Username" />
                <input type="password" name="password" autoComplete="off" id="password" value={loginForm.password} placeholder="Password" onChange={inputChangeHandler} />
                <button type="submit" className="btn-yellow" value="Login">
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
