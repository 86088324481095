import React, { useState, useEffect } from "react";
import CustomTable from "../Components/CustomTable";
import * as config from "../Config/Config";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Moment from "moment";
import Popup from "reactjs-popup";
import StatusUpdate from "./StatusUpdate";
import InputMask from 'react-input-mask';


const Appointments = (props) => {
  const [appointmentList, setAppointmentList] = useState([]);
  const MySwal = withReactContent(Swal);
  
  useEffect(() => {
    getAllAppointments();
    // eslint-disable-next-line
  }, []);

  const updateStatusForm = (updateForm, appointmentId) => {
    console.log(updateForm);
    fetch(`${config.apiUrl}/updateAppointmentStatus/${appointmentId}`,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
      body: JSON.stringify(updateForm)
    }).then((response)=>{
      if(response.ok){
        getAllAppointments();
        MySwal.fire({
          title:'Updated',
          text:'Status updated and notification sent to the customer.'
        })
      }else{
        if (response.status === 401) {
          MySwal.fire({
            title: "Warning",
            text: "You are not authorized to view this content",
          });
        }else if(response.status===501){
          MySwal.fire({
            title: "Warning",
            text: "Status update failed. Please try again.",
          });
        } else {
          MySwal.fire({
            title: "Server Error",
            text: "Please try later.",
          });
        }
      }
    })
  };

  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows])
  
    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    )
  }

  const deleteAppointment = (appointmentId) => {
    fetch(`${config.apiUrl}/appointment/deleteAppointment/${appointmentId}`,{
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
      body:{}
    }).then((response)=>{
      if(response.ok){
        getAllAppointments();
        MySwal.fire({
          title:'Deleted',
          text:'Appointment deleted successfully'
        })
      }else{
        if (response.status === 401) {
          MySwal.fire({
            title: "Warning",
            text: "You are not authorized to view this content",
          });
        }else if(response.status===501){
          MySwal.fire({
            title: "Warning",
            text: "Status update failed. Please try again.",
          });
        } else {
          MySwal.fire({
            title: "Server Error",
            text: "Please try later.",
          });
        }
      }
    })
  }
  

  const columns = React.useMemo(
    () => [
      {
        Header: "Appointment Id",
        accessor: "id",
        sortType: "basic",
        filter:'fuzzyText'
      },
      {
        Header: "Date",
        accessor: (d) => {
          return Moment(d.appointmentDate).utc().format(config.dateFormat);
        },
      },
      {
        Header: "Time Slot",
        accessor: "timeslots.label"
      },
      {
        Header: "Status",
        accessor: 'status.label',
        sortType: "basic",
        Filter:SelectColumnFilter,
        filter:'includes'
      },
      {
        Header: "Technician Name",
        accessor: "pocName",
        sortType: "basic",
      },
      {
        Header: "Technician Mobile",
        accessor: (d) =>{
          return(
            <InputMask mask="999-999-9999" value={d.pocMobile} readOnly />
          )
        },
        sortType: "basic",
      },
      {
        Header: "Action",
        accessor: (d)=>{
          return(
            <div>
            
            <Popup
              trigger={<button className="btn btn-primary" style={{marginTop:'5px', marginLeft:'5px'}}>Details </button>}
              modal
              closeOnDocumentClick
            >
              <div className="model">
                <div className="header text-center ss-margin-tb-15">
                  {" "}
                  <h4>Appointment Details</h4>{" "}
                </div>
                <div className="content ss-margin-tb-15">
                  <table className="table table-bordered table-responsive">
                    <tbody>
                      <tr>
                        <td className="ss-td-title">Customer Name</td>
                        <td className="ss-td-text">
                          {d.appointmentDetails.customerName}
                        </td>
                      </tr>
                      <tr>
                        <td className="ss-td-title">Email</td>
                        <td className="ss-td-text">
                          {d.appointmentDetails.customerEmail}
                        </td>
                      </tr>
                      <tr>
                        <td className="ss-td-title">Mobile</td>
                        <td className="ss-td-text">
                          {d.appointmentDetails.customerMobile}
                        </td>
                      </tr>
                      <tr>
                        <td className="ss-td-title">Address Line 1</td>
                        <td className="ss-td-text">
                          {d.appointmentDetails.customerAddress1}
                        </td>
                      </tr>
                      <tr>
                        <td className="ss-td-title">Address Line 2</td>
                        <td className="ss-td-text">
                          {d.appointmentDetails.customerAddress2}
                        </td>
                      </tr>
                      <tr>
                        <td className="ss-td-title">State</td>
                        <td className="ss-td-text">
                          {d.appointmentDetails.customerState}
                        </td>
                      </tr>
                      <tr>
                        <td className="ss-td-title">Zipcode</td>
                        <td className="ss-td-text">
                          {d.appointmentDetails.customerPincode}
                        </td>
                      </tr>
                      <tr>
                        <td className="ss-td-title">Service Required</td>
                        <td className="ss-td-text">
                          {d.appointmentDetails.serviceType.label}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Popup>
            <Popup
              trigger={<button className="btn btn-info" style={{marginLeft:'5px', marginTop:'5px'}}> Status </button>}
              modal
              closeOnDocumentClick
            >
              {close=>(
                
                <StatusUpdate currentStatus={d.status} pocMobile={d.pocMobile} comments={d.comments} pocName={d.pocName} appointmentId={d.id} close={close} updateButton={updateStatusForm} />
              )}
              
              
            </Popup>
            <button type="button" onClick={()=>deleteAppointment(d.id)} style={{marginLeft:'5px', marginTop:'5px'}} className="btn btn-danger">Delete</button>
            </div>
          )
        },
        filter:undefined
      }
    ],
    // eslint-disable-next-line
    []
  );

  const getAllAppointments = () => {
    fetch(`${config.apiUrl}/getAllAppointments`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
      },
    }).then((response) => {
      if (response.ok) {
        const appList = response.json();
        appList.then((res) => {
          setAppointmentList(res);
        });
      } else {
        if (response.status === 401) {
          MySwal.fire({
            title: "Warning",
            text: "You are not authorized to view this content",
          });
        } else {
          MySwal.fire({
            title: "Server Error",
            text: "Please try later.",
          });
        }
      }
    });
  };

  return <CustomTable data={appointmentList} columns={columns} />;
};

export default Appointments;
