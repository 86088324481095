import React from 'react';
import { useTable, usePagination, useFilters } from 'react-table';
import Select from 'react-select';
import matchSorter from 'match-sorter';
import { Table } from 'react-bootstrap';

export const pageOptionsValues = [
  { value: 10, label:'Show 10'},
  { value: 20, label:'Show 20'},
  { value: 50, label:'Show 50'}
];

const CustomTable = ({ columns, data }) => {

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
    
        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
      } = useTable(
        {
          columns,
          data,
          initialState: { pageIndex: 0 },
          defaultColumn, // Be sure to pass the defaultColumn option
          filterTypes,
        },
        useFilters,
        usePagination
      )


// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val
      

      return (
        <>
          <Table bordered responsive hover {...getTableProps()}>
            <thead className="ss-table-header">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}
                   
               <div style={{color:'black'}}>{column.canFilter ? column.render('Filter') : null}</div>
               </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>
          {/* 
            Pagination can be built however you'd like. 
            This is just a very basic UI implementation:
          */}
           <div className="row ss-flex">
             <div className="col-md-6 ss-width-adjust">
                                   
                                    <Select
                                    className="basic-single ss-width-adjust"
                                    classNamePrefix="select"
                                    defaultValue={pageOptionsValues[0]}
                                    name="color"
                                    options={pageOptionsValues}
                                    onChange={e => {
                                      setPageSize(Number(e.value))
                                      console.log(e);
                                    }}
                                />
                                      
                                      </div>
                                      
                                    <div className="col-md-6 ss-flex-end ss-width-adjust">
                                    <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-default">
                    Prev
                </button>
                                     
                                      
                                      <span className="ss-margin-rl-5">
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <button onClick={() => nextPage()} disabled={!canNextPage} className='btn btn-default'>
                    Next
                </button>
           
                                        
                </div>
                                  
                </div>
        </>
      )
}

export default CustomTable;