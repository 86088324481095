import React from 'react'

const NeedHelp = (props) => {
    return (
        <section className="call_to_action">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-sm-8 col-xs-12">
              <h1>Need help with financing?</h1>
            </div>
            <div className="col-md-3 col-sm-4 col-xs-12 text-right cta_responsive_left">
              <a href="/" className="btn-gray">
               CLICK HERE
              </a>
            </div>
          </div>
        </div>
      </section>
    )
}
export default NeedHelp