import React, { useEffect, useState } from "react";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import Select from 'react-select';
import * as config from '../Config/Config';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DateTime from 'react-datetime';
import '../../Assets/css/react-datetime.css'
import Moment from 'moment';
import validator from 'validator';
import InputMask from 'react-input-mask'; 

const initialValue = {
  customerName:'',
  customerEmail:'',
  customerMobile:'',
  serviceType:'',
  customerDate:'',
  customerTime:'',
  customerAddress1:'',
  customerAddress2:'',
  customerState:'Texas',
  customerPincode:'',
}

const ScheduleForm = (props) => {

  const MySwal = withReactContent(Swal);
  const [serviceList, setServiceList] = useState([]);
  const [scheduleForm, setScheduleForm] = useState(initialValue);
  const [timeSlotList, setTimeSlotList] = useState([]);
  const [validMobile, setValidMobile] = useState(false);
  const [serviceValid, setServiceValid] = useState(false);
  const [dateValid, setDateValid] = useState(false);
  const [timeValid, setTimeValid] = useState(false);

  const submitHandler = (event) => {
    event.preventDefault();
    if(scheduleForm.serviceType!=="" && scheduleForm.serviceType!==null && scheduleForm.customerDate!=="" && scheduleForm.customerDate!==null && scheduleForm.customerTime!=="" && scheduleForm.customerTime!==null){
      fetch(`${config.apiUrl}/schedule/createAppointment`,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify(scheduleForm)
      }).then((response)=>{
        if(response.ok){
          setScheduleForm(initialValue);
          setServiceValid(false)
          setTimeValid(false);
          setDateValid(false)
          MySwal.fire({
            title: 'Success',
            text:'Appointment scheduled. You will get the confirmation mail with point of contact details. Thank you!'
          })
        }else{
          if (response.status === 401) {
            MySwal.fire({
              title: "Warning",
              text: "You are not authorized to view this content",
            });
          }else if(response.status===501){
            MySwal.fire({
              title: "Warning",
              text: "Status update failed. Please try again.",
            });
          } else {
            MySwal.fire({
              title: "Server Error",
              text: "Please try later.",
            });
          }
        }
      })
    }else{
      if(scheduleForm.serviceType!=="" && scheduleForm.serviceType!==null){
        setServiceValid(false);
      }else{
        setServiceValid(true);
      }
      if(scheduleForm.customerDate!=="" && scheduleForm.customerDate!==null){
        setDateValid(false);
      }else{
        setDateValid(true);
      }
      if(scheduleForm.customerTime!=="" && scheduleForm.customerTime!==null){
        setTimeValid(false);
      }else{
        setTimeValid(true);
      }
    }

    
  };

  const currentDayValidation = (current) => {
    var yesterday = Moment(new Date()).subtract( 1, 'day' );
    return current.isAfter( yesterday ) && current.day() !== 0;
  }

  const inputChangeHandler = (e) => {
    var formValues = {
      ...scheduleForm
    }
    formValues[e.target.name] = e.target.value;
    if(e.target.name==="customerMobile"){
      if(validator.isMobilePhone(e.target.value)){
        setValidMobile(false);
      }else{
        setValidMobile(true);
      }
    }
    setScheduleForm(formValues);
  }

  const dateChangeHandler = (e) => {
    var formValues = {
      ...scheduleForm
    }
    formValues['customerDate'] = Moment(e).local().format(config.dateFormat)
    formValues['customerTime'] = null
    setDateValid(false)
    setScheduleForm(formValues);
    const data = {
      customerDate : Moment(e).format(config.dateFormat),
      currenTime: Moment().local().add(3,"hours").format("hhA")
    } 
    console.log(Moment(e).local())
    fetch(`${config.apiUrl}/schedule/getTimeList`,{
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify(data)
    }).then((response)=>{
        if(response.ok){
          const ss = response.json()
          ss.then(js=>{
            setTimeSlotList(js);
          })
        }
      })
  }

  useEffect(()=>{
    getAllServices();
    // eslint-disable-next-line
  },[])

  const getAllServices = () => {
    fetch(`${config.apiUrl}/schedule/getAllServices`,{
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
    }).then((response)=>{
      if(response.ok){
        const serviceResp = response.json();
        serviceResp.then((json)=>{
          setServiceList(json);
        })
      }else{
        if (response.status === 401) {
          MySwal.fire({
            title: "Warning",
            text: "You are not authorized to view this content",
          });
        } else {
          MySwal.fire({
            title: "Server Error",
            text: "Please try later.",
          });
        }
      }
    })
  }

  const styles = {
      control: style=>({
          ...style,
          borderRadius: '2px',
          background: '#F1F1F1',
          borderColor: '#F1F1F1',
          paddingLeft: '12px',
          color: '#87868a'

      }),
      singleValue: style=>({
          ...style,
          color:'#87868a'
      })
  }

  return (
    <section className="contact_form_area contact_us section_padding">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="hero-title-with-shape">
              <h4>Schedule your appointment here</h4>
            </div>
            <div className="contact-right contact-right-style-2 responsive_mt">
              <ValidationForm onSubmit={submitHandler} onErrorSubmit={()=>{

    if(validator.isMobilePhone(scheduleForm.customerMobile)){
      setValidMobile(false);
    }
    else
    {
      setValidMobile(true)
    }
              }}>
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <TextInput
                      className="input"
                      type="text"
                      name="customerName"
                      id="customerName"
                      autoComplete="off"
                      placeholder="Name"
                      required
                      value={scheduleForm.customerName}
                      onChange={inputChangeHandler}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <TextInput
                      className="input"
                      type="text"
                      ma
                      name="customerEmail"
                      autoComplete="off"
                      id="customerEmail"
                      placeholder="Email"
                      required
                      validator = {validator.isEmail}
                      errorMessage = {{
                        validator: 'Please enter valid email'
                      }}
                      value={scheduleForm.customerEmail}
                      onChange={inputChangeHandler}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <InputMask mask="999-999-9999" name="customerMobile" autoComplete="off" id="customerMobile" required 
                    value={scheduleForm.customerMobile} placeholder="Mobile" onChange={inputChangeHandler} />
                    {validMobile? <div class="invalid-feedback">Please enter valid mobile</div>:null}
                   
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <Select
                      className="input ss-select-style"
                      name="serviceType"
                      id="serviceType"
                      placeholder="Select Service"
                      options={serviceList}
                      required
                     styles={styles}
                      value = {scheduleForm.serviceType}
                      onChange = {(e)=>{
                        var formValues = {
                          ...scheduleForm
                        }
                        formValues['serviceType'] = e
                        setServiceValid(false);
                        setScheduleForm(formValues);
                      }}
                    />
                     {serviceValid? <div class="invalid-feedback">This field is required</div>:null}
                  </div>
                  <div className="col-md-4 col-sm-6">
                  <DateTime 
                 closeOnSelect
                 placeholder="Date"
                 timeFormat={false}
                 dateFormat={config.dateFormat}
                 inputProps={{placeholder:'Date', id:"customerDate", name:"customerDate",className:"input", autoComplete:"off"}}
                 onChange={dateChangeHandler}
                 value={scheduleForm.customerDate}
                 isValidDate={currentDayValidation}
                 
                  />
                  {dateValid? <div class="invalid-feedback">This field is required</div>:null}
                  </div>
                  <div className="col-md-4 col-sm-6">
                  <Select
                      className="input ss-select-style"
                      name="customerTime"
                      id="customerTime"
                      options={timeSlotList}
                      placeholder="Select Timeslot"
                     styles={styles}
                     value = {scheduleForm.customerTime}
                     onChange = {(e)=>{
                       var formValues = {
                         ...scheduleForm
                       }
                       formValues['customerTime'] = e
                       setTimeValid(false)
                       setScheduleForm(formValues);
                     }}
                    />
                    {timeValid? <div class="invalid-feedback">This field is required</div>:null}
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <TextInput
                      className="input"
                      type="text"
                      name="customerAddress1"
                      id="customerAddress1"
                      autoComplete="off"
                      placeholder="Address Line 1"
                      required
                      value={scheduleForm.customerAddress1}
                      onChange={inputChangeHandler}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <TextInput
                      className="input"
                      type="text"
                      name="customerAddress2"
                      autoComplete="off"
                      id="customerAddress2"
                      placeholder="Address Line 2"
                      required
                      value={scheduleForm.customerAddress2}
                      onChange={inputChangeHandler}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <TextInput
                      className="input"
                      type="text"
                      name="customerState"
                      id="customerState"
                      autoComplete="off"
                      placeholder="State"
                      required
                      disabled
                      value={scheduleForm.customerState}
                      onChange={inputChangeHandler}
                    />
                  </div>
                  </div>
                  <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <TextInput
                      className="input"
                      type="text"
                      name="customerPincode"
                      autoComplete="off"
                      id="customerPincode"
                      placeholder="Zipcode"
                      required
                      validator = {(e)=>{
                        
                        if(e.length === 5 && validator.isNumeric(e)){
                          return true
                        }else{
                          return false
                        }
                      }}
                      errorMessage = {{
                        validator: 'Please enter valid zipcode'
                      }}
                      value={scheduleForm.customerPincode}
                      onChange={inputChangeHandler}
                    />
                  </div>
                </div>

                <button type="submit" className="btn-yellow" value="Login">
                  Submit
                </button>
              </ValidationForm>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ScheduleForm;
