import React, { useState, useEffect } from "react";
import Select from "react-select";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import Aux from "../hoc/_Aux/Index";
import InputMask from 'react-input-mask';

export const statusList = [
    { value: 1, label:'Appointment Scheduled'},
    { value: 2, label:'Confirmed'},
    { value: 3, label:'Completed'},
    { value: 4, label:'Rejected'}
  ];


const initialValue = {
    status:null,
    pocName:'',
    pocMobile:'',
    comments:''
}


const StatusUpdate = (props) => {
    const [updateForm, setUpdateForm] = useState(initialValue);

    const formSubmitHandler = (event) => {
        event.preventDefault();
        props.updateButton(updateForm, props.appointmentId);
        props.close();
    }

    useEffect(()=>{
        var formValues = {
            ...updateForm
        }
        formValues['status'] = props.currentStatus;
        formValues['pocName'] = props.pocName===null?"":props.pocName;
        formValues['pocMobile'] = props.pocMobile===null?"":props.pocMobile;
        formValues['comments'] = props.comments===null?"":props.comments;
        setUpdateForm(formValues);
        // eslint-disable-next-line
    },[])

    const inputChangeHandler = (e) => {
        var formValues = {
            ...updateForm
        }
        formValues[e.target.name] = e.target.value;
        setUpdateForm(formValues);
    }
  return (
    <div className="model">
      <div className="header text-center ss-margin-tb-15">
        {" "}
        <h4>Status Update</h4>{" "}
      </div>
      <div className="content ss-margin-tb-15">
        <ValidationForm onSubmit={formSubmitHandler}>
          <Select
            className="basic-single ss-width-adjust ss-margin-tb-15"
            classNamePrefix="select"
            name="color"
            options={statusList}
            value={updateForm.status}
            
            onChange={(e) => {
              var formvalues = {
                ...updateForm,
              };
              formvalues['status'] = e;
              console.log(e);
              setUpdateForm(formvalues);
            }}
          />
          {updateForm.status && updateForm.status.value===2?(<Aux>
          <TextInput
            type="text"
            name="pocName"
            id="pocName"
            className="form-control ss-margin-tb-15"
            autoComplete="off"
            placeholder="Technician Name"
            value={updateForm.pocName}
            required
            onChange={inputChangeHandler}
          />

          <InputMask mask="999-999-9999" className="form-control" style={{marginBottom:'5px'}} name="pocMobile" autoComplete="off" id="pocMobile" required 
                    value={updateForm.pocMobile} placeholder="Technician Mobile" onChange={inputChangeHandler} />

        </Aux>):null}
          {updateForm.status && (updateForm.status.value===4 || updateForm.status.value===3)?(<Aux><TextInput
            type="text"
            name="comments"
            className="form-control ss-margin-tb-15"
            autoComplete="off"
            id="comments"
            placeholder="Comments"
            required
            value={updateForm.comments}
            onChange={inputChangeHandler}
          /></Aux>):null}
          <button type="submit" className="btn-yellow" value="Login">
            Update
          </button>
        </ValidationForm>
      </div>
    </div>
  );
};

export default StatusUpdate;
