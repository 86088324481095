import React, { useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Aux from "../hoc/_Aux/Index";
import Login from "./Login";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Appointments from "./Appointments";

const Admin = (props) => {
  const [showlogin, setShowLogin] = useState(true);

  const checkAuth = (status) => {
    setShowLogin(status);
  };

  const logoutTabHandler = (event) =>{
    sessionStorage.removeItem("jwtToken");
    setShowLogin(true);
  }
  return (
    <Aux>
      <div className="fixed-scroll-nav">
        <Header />
        <section className="breadcrumb_section text-center section_padding">
          <ul className="breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li> Admin</li>
          </ul>
          <h1>Admin</h1>
        </section>
        {showlogin ? (
          <Login checkAuth={checkAuth} />
        ) : (
          <section className="section_padding contact_form_area contact_us ">
            <div className="container">
              <div className="row">
                <div className="col">
            <Tabs>
              <TabList>
                <Tab>Appointments</Tab>
                
                <Tab onClick={logoutTabHandler}>Logout</Tab>
              </TabList>

              <TabPanel>
               <Appointments />
              </TabPanel>
             
              <TabPanel>
                <h2>Any content 2</h2>
              </TabPanel>
            </Tabs>
            </div>
              </div>
            </div>
          </section>
        )}

        <Footer />
      </div>
    </Aux>
  );
};

export default Admin;
