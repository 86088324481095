import React from 'react';

const WhyUs = (props) => {
    return(
        <section className="about_service_area section_padding">
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="about_service_left">
                    <p className="about_service_year text-center">
                        20
                        <span>years
                        experience</span>
                    </p>
                </div>
            </div>
            <div className="col-md-6">
                <div className="about_service_right">
                    <div className="hero-title-with-shape">
                        <h4 className="heading_with_border">Why choose us</h4>
                        <h1>We are trusted source of AC and heating maintenance solutions</h1>
                    </div>
                    <ul>
                        <li><i className="fa fa-check-circle"></i> Unmatched performance, satisfaction service guarantees</li>
                        <li><i className="fa fa-check-circle"></i> Home protection through our shoe covers and mats</li>
                        <li><i className="fa fa-check-circle"></i> Upfront, flat rate pricing—no overtime charges</li>
                        <li><i className="fa fa-check-circle"></i> 24 / 7 availability for all emergency services</li>
                        <li><i className="fa fa-check-circle"></i> Fixed right promise—done right or it’s free</li>
                        <li><i className="fa fa-check-circle"></i> Clear communication and updates on service arrival</li>
                    </ul>
                    <div className="about_service_discount text-center">
                        <h1>10% off</h1>
                        <p>on our all services for senior citizens and veterans.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    )
}

export default WhyUs;