import React from 'react';
import serviceDetailsImg from '../../Assets/images/services/service-3.jpg'

const Details = (props) => {
    return (
        <section className="service_details_area section_padding">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="service_details_left">
                    <img className="ss-image-resize" src={serviceDetailsImg} alt="Service Details" />
                    <h1>Beach/Coastal</h1>
                    <p>We offer service to many Texas coastal communities. Are you looking for a reliable trustworthy company to resolve the issues at your vacation home. Tired of trying to track down a local that can help? Coastal properties require specific treatment due to the corrosive environment and location of equipment.</p>
                    <h2>Common Air Conditioner Problems</h2>
                    <ul>
                        <li><i className="fa fa-check-circle"></i> The air conditioner won't turn on.</li>
                        <li><i className="fa fa-check-circle"></i> Weird noises or vibrating occur at startup or during operation.</li>
                        <li><i className="fa fa-check-circle"></i> Warm air exits the supply registers.</li>
                        <li><i className="fa fa-check-circle"></i> The unit hums, but the fan doesn't turn on.</li>
                        <li><i className="fa fa-check-circle"></i> The fan runs, but the compressor isn't working.</li>
                        <li><i className="fa fa-check-circle"></i> Water is pooling around the air conditioner</li>
                    </ul>
                    <p className="extra_mr">If you believe your air conditioning unit requires emergency service, <a href="/contact">click here</a> for information about our 24/7 repair services.</p>
                    <h2>Should I Repair or Replace My Air Conditioner?</h2>
                    <ul className="service_details_ac">
                        <li><i className="fa fa-check-circle"></i> Age - If your AC is more than 10 years old and giving you trouble, it may be in your best interest of consider a replacement.</li>
                        <li><i className="fa fa-check-circle"></i> Investment - Is a new system a better investment in the long run? A Superior Solutions air conditioner technician can help you calculate your return on investment to determine if on upgrade in more beneficial than repair.</li>
                        <li><i className="fa fa-check-circle"></i> Utility rates - You may think you can't afford on air conditioner replacement, but a more efficient unit consumes less electricity and could save you big time on energy bills.</li>
                    </ul>
                   
                </div>
            </div>
            
        </div>
    </div>
</section>
    )
}

export default Details;