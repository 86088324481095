import React, { useEffect } from "react";
import Aux from "../hoc/_Aux/Index";
import Header from "../Components/Header";
import Carousal from "./Carousel";
import StartStory from "./StartStory";
import Services from "./Services";
import Footer from "../Components/Footer";
import Testimonials from "./Testimonials";
import Gallery from "./Gallery";

const Home = (props) => {
  
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
  return (
    <Aux>
      <div className="fixed-scroll-nav">
       
        <Header />
        <Carousal />
        <div className="feature_service_area text-center">
          <div className="feature_service_box_width">
            <i className="icon-wallet"></i>
            <p>No Upfront Payments</p>
          </div>
          <div className="feature_service_box_width">
            <i className="icon-diploma"></i>
            <p>Satisfaction Guarantee</p>
          </div>
          <div className="feature_service_box_width">
            <i className="icon-big_clock"></i>
            <p>Emergency Service</p>
          </div>
          <div className="feature_service_box_width">
            <i className="icon-handshake"></i>
            <p>Fixed Right Promise</p>
          </div>
          <div className="feature_service_box_width">
            <i className="icon-mechanic"></i>
            <p>20+ Years Experience</p>
          </div>
        </div>
        <StartStory />
        <Services />
        <Gallery />
        <Testimonials />
        {/* <section className="call_to_action">
          <div className="container">
            <div className="row">
              <div className="col-md-9 col-sm-8 col-xs-12">
                <h1>Need help with financing?</h1>
              </div>
              <div className="col-md-3 col-sm-4 col-xs-12 text-right cta_responsive_left">
                <a href="" className="btn-gray">
                 CLICK HERE
                </a>
              </div>
            </div>
          </div>
        </section> */}
        <Footer />
      </div>
    </Aux>
  );
};

export default Home;
