import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import './Assets/css/bootstrap.min.css';
import './Assets/css/font-awesome.min.css';
import './Assets/css/owl.carousel.min.css';
import './Assets/css/fontello.css';
import './Assets/css/animate.css';
import './Assets/css/style.css';
import './Assets/css/responsive.css';
import './Assets/css/superior.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

ReactDOM.render(
  app,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
