import React from 'react';
import OwlCarousel from "react-owl-carousel";

const Carousel = (props) => {
    return(
        <OwlCarousel
          className="header_slider_area owl-carousel"
          dots
          autoplay
          loop
          animateIn="slideInLeft"
          animateOut="fadeOutRight"
          autoplayTimeout={7000}
          items={1}
          autoplaySpeed={300}
        >
          <div className="header_slider_bg">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <span className="ss-white">Reliable Air
                    Conditioning</span> 
                  </p>
                  <h1 className="ss-white">Installation Services &amp; Repair</h1>
                  {/* <div className="slide_button">
                    <a href="#" className="btn-yellow">
                      GET A FREE QUOTE
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="header_slider_bg slider_bg2">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <span className="ss-white">Choose the Best
                    Company</span>
                  </p>
                  <h1 className="ss-white">Heating and Air Conditioning Specialist</h1>
                  {/* <div className="slide_button">
                    <a href="#" className="btn-yellow">
                      GET A FREE QUOTE
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="header_slider_bg slider_bg3">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <span className="ss-white">Quality that is
                    Guaranteed</span>
                  </p>
                  <h1 className="ss-white">Expert HVAC Services</h1>
                  {/* <div className="slide_button">
                    <a href="#" className="btn-yellow">
                      GET A FREE QUOTE
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
    )
}

export default Carousel;