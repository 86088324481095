import React from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import HomeComponent from './SuperiorSolutions/Home/Home';
import AboutUsComponent from './SuperiorSolutions/AboutUs/AboutUs';
import AdminComponent from './SuperiorSolutions/Admin/Admin';
import ScheduleNowComponent from './SuperiorSolutions/ScheduleNow/ScheduleNow';
import ServicesComponent from './SuperiorSolutions/Services/Services';
import ServiceDetailsComponent from './SuperiorSolutions/ServiceDetails/ServiceDetails';
import ResidentialDetails from './SuperiorSolutions/ServiceDetails/ResidentialDetails';
import CommercialDetails from './SuperiorSolutions/ServiceDetails/CommercialDetails';
import ContactComponent from './SuperiorSolutions/Contact/Contact';

function App() {
  return (
    <Switch>
      <Route path="/" exact component={HomeComponent} />
      <Route path="/aboutus" component={AboutUsComponent} />
      <Route path="/services" component={ServicesComponent} />
      <Route path="/service-details" component={ServiceDetailsComponent} />
      <Route path="/commercial-details" component={CommercialDetails} />
      <Route path="/residential-details" component={ResidentialDetails} />
      <Route path="/contact" component={ContactComponent} />
      <Route path="/admin" exact component={AdminComponent} />
      <Route path="/schedule-now" exact component={ScheduleNowComponent} />
      <Route path="*" exact component={HomeComponent} />
    </Switch>
  );
}

export default App;
